import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { RoomData } from 'shared-models';

@Component({
  selector: 'app-agent-call-dialog',
  templateUrl: './agent-call-dialog.component.html',
  styleUrls: ['./agent-call-dialog.component.scss']
})
export class AgentCallDialogComponent implements OnInit {
  property: string = "";
  unit: string = "";
  title: string = "Incoming call";

  constructor(@Inject(MAT_DIALOG_DATA) public data: RoomData) { 
      this.property = data.Address;
      this.unit = data.UnitName || "Leasing";
      this.title = "Incoming call";
    }
    
  ngOnInit(): void {
  }
}
