import {
  Component,
  OnDestroy,
  OnInit,
  Injector,
  HostListener,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserIdleDialogComponent } from './user-idle-dialog/user-idle-dialog.component';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LoggingApiService, EventBusService, AuthenticatedUserService, ToastService } from 'shared-services';
import { CreateLogDTO, NotifyAgentDTO, RoomData, UserClientDTO, NotificationDTO } from 'shared-models';
import { GlobalHelperService } from 'src/app/core/Helpers/global-helper.service';
import { ConfirmationService } from 'primeng/api';
import { NetworkDetectorService } from 'shared-services';
import { AgentCallInviteDialogComponent } from 'src/app/agent/agent-call-invite-dialog/agent-call-invite-dialog.component';
import {
  StateHub,
  StateHubSignalRService,
} from 'shared-services';
import { filter } from 'rxjs/operators';
import { AgentCallDialogComponent } from 'src/app/agent/agent-call-dialog/agent-call-dialog.component';
import { DataMissingErrorComponent } from '../error-message/data-missing-error/data-missing-error.component';
import { LoggingService } from 'shared-services';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { AgentMobileCallingDialogComponent } from 'src/app/agent/agent-mobile-calling-dialog/agent-mobile-calling-dialog.component';

export interface CallData {
  CallId: number;
  CallType: string;
  KioskId: string;
  Address: string;
  PeerId: string;
  UserPropertyId: number;
  AgentUserId: string;
  AgentImage: string;
  IsCallTransferred: boolean;
  TransferCallAgent: string;
  UnitNumber: string;
}

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  ringTone = new Audio(`..\\..\\..\\assets\\${environment.ringtoneFileName}`);
  property: string = '';
  unit: string = '';
  incomingCall: boolean = false;
  isCallOpened: boolean = false;
  isCallTransfered: boolean = false;
  initiatedTransferCallAgentUsername: string;

  onlineStatus: boolean = navigator.onLine;
  toastClass = ['toast-class'];
  showsToast = false;
  eventBusSub?: Subscription;
  dialogRefs: any = [];

  constructor(
    private stateHubService: StateHubSignalRService,
    private matDialog: MatDialog,
    private loggingService: LoggingApiService,
    private globalHelper: GlobalHelperService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToastService,
    private networkDetector: NetworkDetectorService,
    private confirmationService: ConfirmationService,
    private logger: LoggingService,
    private eventBusService: EventBusService,
    private authenticatedUserService: AuthenticatedUserService,
    private authService: AuthService
  ) {
    this.onlineStatus = navigator.onLine;
    this.networkDetector.changed$.subscribe((online) => {
      if (this.onlineStatus != online) {
        this.showsToast = true;
        this.onlineStatus = online;
      }
    });

    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (
          !event.urlAfterRedirects ||
          !event.urlAfterRedirects.includes('/agent/video-stream')
        ) {
          localStorage.removeItem('InvitedByCallStatusId');
        }
      });

    this.eventBusSub = this.eventBusService.on('logout', (data) => {
      if (data) {
        this.logout();
      }
    });
  }



  ngOnDestroy(): void {
    this.stateHubService.listenOff(StateHub.RequestCall);
    if (this.eventBusSub)
      this.eventBusSub.unsubscribe();
  }

  idleTimerLeft: string;
  secondTimerLeft: string;
  timeWatchIntervalMinutes: number = 10;

  ngOnInit(): void {
    this.listenOnCall();
    this.listenOnCallNotApproved();
    this.listenOnCancelCall();
    localStorage.setItem('isTimerStarted', 'false');
    this.checkTimeToEndSession();

    this.stateHubService.listenOn("sessionExpired", () => {
      const url = this.router.url;
      if (!url.includes('agent/thankyou')) {
        localStorage.removeItem('loggedIn');
        this.authService.signOut();
      }
    })
  }

  logout(): void {
    localStorage.removeItem('loggedIn');
    this.router.navigate(["agent/thankyou"]);
  }


  public showCallNotification(address: string, unit: string) {
    const notification = new Notification(
      `${address} ${(unit || 'Leasing').trim()} is calling!`,
      {
        body: 'Can you please answer it?',
      }
    );
    notification.addEventListener('click', () => {
      window.parent.parent.focus();
    });
  }

  public onUnload(): void {
    var callDialog = document.getElementsByClassName('answer-call-dialog');
    if (callDialog && callDialog.length) {
      var message = {
        message: 'Agent declined the call because browser unloaded!',
        user: localStorage.username,
        callType: this.globalHelper.getCallType(),
      };

      this.loggingService.logInformation(
        new CreateLogDTO({
          message: message.message,
        })
      );
      this.stateHubService.sendMessage(
        StateHub.SendDeclineCallToClient,
        message
      );
      return;
    }
  }

  private listenOnCallNotApproved() {
    try {
      this.stateHubService.listenOn(StateHub.CallNotApproved, (event) => {
        localStorage.setItem('hasCallNotApprovedNotification', 'true');
        this.router
          .navigate(['/agent/dashboard'], {
            queryParams: { isCallNotApproved: true },
          })
          .then(() => window.location.reload());
      });
    } catch (error) {
      console.error(error);
    }
  }

  private listenOnCancelCall(): void {
    try {
      this.stateHubService.listenOn(StateHub.CancelCall, (event) => {
        this.incomingCall = false;
        this.isCallTransfered = false;
        if (this.dialogRefs.length > 0) {
          this.dialogRefs.forEach((element: MatDialogRef<any, any>) => {
            if (element.componentInstance != null) {
              var dialogUUID = element.componentInstance.data.UUID;
              if (event == dialogUUID) {
                element.close();
                this.rearrangeDialogs();
                return;
              }
            }
          });
        }
        this.loggingService.logInformation(
          new CreateLogDTO({
            message:
              'Tenant cancelled the call. Event: ' + JSON.stringify(event),
          })
        );

        if (event === 'GetId') {
          this.stateHubService.sendMessage(
            'getId',
            this.globalHelper.getUserForSignalR()
          );
        }
      });

      this.stateHubService.listenOn(StateHub.CallHasBeenAccepted, (uuid) => {
        this.incomingCall = false;
        this.isCallTransfered = false;
        if (this.dialogRefs.length > 0) {
          this.dialogRefs.forEach((element: MatDialogRef<any, any>) => {
            if (element.componentInstance != null) {
              var dialogUUID = element.componentInstance.data.UUID;
              if (uuid == dialogUUID) {
                element.close();
                this.rearrangeDialogs();
                return;
              }
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  private startAudio(): void {
    try {
      this.ringTone.loop = true;
      this.ringTone.currentTime = 0;
      this.ringTone.play();
    } catch (error) {
      console.error(error);
    }
  }

  private listenOnCall(): void {
    try {
      this.stateHubService.listenOn(
        StateHub.RequestCall,
        (username: string, callData: string) => {
          var logMessage =
            'Received call: ' +
            callData +
            '\n isCallOpened: ' +
            this.isCallOpened;
          this.loggingService.logInformation(
            new CreateLogDTO({ message: logMessage })
          );

          if (callData && !this.isCallOpened) {
            var call = <RoomData>{
              ...JSON.parse(callData),
            };

            this.startAudio();

            this.openInviteDialog(username, call);

          }
        }
      );

      this.stateHubService.listenOn(
        StateHub.Call,
        (callData: string, userData: string) => {
          var logMessage =
            'Received call: ' +
            callData +
            '\n isCallOpened: ' +
            this.isCallOpened;
          this.loggingService.logInformation(
            new CreateLogDTO({ message: logMessage })
          );
          var call = <RoomData>{
            ...JSON.parse(callData),
          };
          var user = <UserClientDTO>{
            ...JSON.parse(userData),
          };

          this.startAudio();

          this.openCallDialog(user.UserName, call);

        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  openInviteDialog(data: string, callData: RoomData): void {
    try {
      this.loggingService.logInformation(
        new CreateLogDTO({ message: 'Trying to open Dialog to answer call' })
      );
      const matDialogRef = this.matDialog.open(AgentCallInviteDialogComponent, {
        disableClose: true,
        data: callData,
        panelClass: 'answer-call-dialog',
      });
      this.loggingService.logInformation(
        new CreateLogDTO({
          message: 'State of Dialog to answer call is open?',
        })
      );
      this.rearrangeDialogs();
      matDialogRef.afterClosed().subscribe((accepted) => {
        this.loggingService.logInformation(
          new CreateLogDTO({ message: 'Closed Dialog to answer call' })
        );
        this.isCallOpened = false;
        this.ringTone.pause();
        if (accepted === 'accepted') {
          this.matDialog.closeAll();
          this.routeToCall(callData);
        } else {
          this.stateHubService.sendMessage("AgentDeclined", <NotifyAgentDTO>{
            UUID: callData.UUID,
            AgentUserId: localStorage.username,
            InvitedByCallStatusId: callData.InvitedByCallStatusId,
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  rearrangeDialogs() {
    setTimeout(() => {
      //this piece of code arranges dialog components when receiving a call from kiosk
      const overlayContainer = document.querySelector('.cdk-overlay-container');
      const globalOverlayWrappers = document.querySelectorAll('.cdk-global-overlay-wrapper');

      if (overlayContainer.children.length > 2) {
        // Adding CSS classes
        overlayContainer.classList.add('cdkOverlayContainerManual');
        globalOverlayWrappers.forEach(el => el.classList.add('cdkGlobalOverlayWrapperManual'));
        document
          .querySelectorAll('.cdk-overlay-pane')
          .forEach(el => el.classList.add('cdkOverlayPane'));

        // Handling multiple backdrops
        const backdrop = overlayContainer.querySelector('.cdk-overlay-backdrop');
        if (overlayContainer.querySelectorAll('.cdk-overlay-backdrop').length > 1) {
          overlayContainer
            .querySelectorAll('.cdk-overlay-backdrop')
            .forEach(el => el.remove());
          // Inserting backdrop as first child
          overlayContainer.insertBefore(backdrop, overlayContainer.firstChild);
        }

        globalOverlayWrappers.forEach((el: HTMLElement) => el.style.gridColumn = 'unset');
      }
      else {
        (document.querySelector('.cdk-global-overlay-wrapper') as HTMLElement).style.gridColumn = 'span 2';
      }
    }, 100);
  }

  openCallDialog(data: string, callData: RoomData): void {
    try {
      this.loggingService.logInformation(
        new CreateLogDTO({ message: 'Trying to open Dialog to answer call' })
      );
      var matDialogRef: MatDialogRef<any, any>
      if (callData.TenantName) {
        matDialogRef = this.matDialog.open(AgentMobileCallingDialogComponent, {
          disableClose: true,
          data: callData,
          panelClass: 'answer-call-dialog',
        });
      } else {
        matDialogRef = this.matDialog.open(AgentCallDialogComponent, {
          disableClose: true,
          data: callData,
          panelClass: 'answer-call-dialog',
        });
      }

      this.dialogRefs.push(matDialogRef);

      this.rearrangeDialogs();

      this.loggingService.logInformation(
        new CreateLogDTO({
          message: 'State of Dialog to answer call is open?',
        })
      );
      matDialogRef.afterClosed().subscribe((accepted) => {
        this.logger.info(accepted);
        this.loggingService.logInformation(
          new CreateLogDTO({ message: 'Closed Dialog to answer call' })
        );
        this.isCallOpened = false;
        this.ringTone.pause();
        if (accepted === 'accepted') {
          this.matDialog.closeAll();
          this.routeToCall(callData);
        } else {
          if (accepted == '') {
            this.stateHubService.sendMessage('Declined', <NotifyAgentDTO>{
              UUID: callData.UUID,
              AgentUserId: localStorage.username,
            });
            this.stateHubService.sendMessage('AgentDeclined', <NotifyAgentDTO>{
              UUID: callData.UUID,
              AgentUserId: localStorage.username,
              InvitedByCallStatusId: callData.InvitedByCallStatusId,
            });
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  routeToCall(calldata: RoomData): void {
    this.canUseVideoCam()
      .then(async (response) => {
        if (!response.available) {
          if (response.error.code === 0) {
            this.confirmationService.confirm({
              key: 'camInUseDialog',
              message:
                'Your camera is in use within another software.<br/>You need turn it off in the other software.',
              accept: () => { },
            });
            return;
          }
          alert(response.error.message);
          this.loggingService.logCritical(
            new CreateLogDTO({
              message: 'routeToVideoCall() error = ' + response.message,
            })
          );
          return;
        }
        if (
          calldata.InvitedByCallStatusId &&
          calldata.InvitedByCallStatusId != null
        )
          localStorage.setItem(
            'InvitedByCallStatusId',
            calldata.InvitedByCallStatusId.toString()
          );
        var accepted = await this.stateHubService.invoke<boolean>(
          StateHub.Accepted,
          <NotifyAgentDTO>{
            UUID: calldata.UUID,
            AgentUserId: localStorage.username,
            InvitedByCallStatusId: calldata.InvitedByCallStatusId ?? 0,
          }
        );
        if (accepted) {
          this.router.navigate(['agent/video-stream'], {
            queryParams: { uuid: calldata.UUID },
          });
        } else {
          this.toasterService.showNotification(
            DataMissingErrorComponent,
            'Call not successful!',
            'Another agent accepted the call',
            false,
            7000
          );
        }
      })
      .catch((e: unknown) => {
        if (e instanceof Error) {
          this.logger.error('An error occurred while routing to video stream');
          this.loggingService.logError(
            new CreateLogDTO({ message: e.message })
          );
        }
      });
  }

  private async canUseVideoCam(): Promise<any> {
    try {
      return await navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: false,
        })
        .then((stream) => {
          stream.getTracks().forEach((track) => track.stop());
          return {
            available: true,
            error: null,
          };
        })
        .catch((e) => {
          this.logger.error("Couldn't get media stream");
          this.loggingService.logError(new CreateLogDTO({ message: e }));

          return {
            available: false,
            error: e,
          };
        });
    } catch (error) {
      console.error(error);
    }
  }

  declineCall(data: CallData): void {
    var message = {};
    message = {
      message: 'Agent did not respond!',
      user: localStorage.username,
      roles: this.globalHelper.getRoles(),
      callType: this.globalHelper.getCallType(),
    };

    this.loggingService.logInformation(
      new CreateLogDTO({ message: 'Declined the call!' })
    );

    if (data.IsCallTransferred) {
      this.stateHubService.sendMessage(StateHub.DeclineTransfer, {
        callId: data.CallId,
        user: localStorage.username,
      });
    } else {
      this.stateHubService.sendMessage(
        StateHub.SendDeclineCallToClient,
        message
      );
    }
    data.IsCallTransferred = false;
    this.stateHubService.sendMessage(
      StateHub.GetId,
      this.globalHelper.getUserForSignalR()
    );
  }

  checkTimeToEndSession() {
    try {
      localStorage.setItem('isTimerStarted', 'false');
      var interval = setInterval(() => {
        var isTimerStarted = localStorage.getItem('isTimerStarted');

        var date = new Date();
        if (date.getHours() >= 23 && isTimerStarted == 'false') {
          localStorage.setItem('isTimerStarted', 'true');
          this.endSessionTimer();
        }
      }, 1000 * 60 * this.timeWatchIntervalMinutes);
    } catch (error) { }
  }

  endSessionTimer() {
    try {
      setTimeout(() => {
        this.matDialog.open(UserIdleDialogComponent, {
          disableClose: true,
          data: {
            minutes: environment.endSessionMinutes,
          },
        });
      }, 1000 * 60 * environment.showDialogEndSessionInMinutes);
    } catch (error) { }
  }
}
